export const theme = {
  black: '#131421',
  red: '#e3000b',
  white: '#ffffff',
  gray: '#5f7adb',
  darkGray: '#666666',
  yellow: '#feda03',
  sand: '#ffffe8',
  green: '#1CAA50',
  startColorShadow: '#e7e8e9',
  dark: '#2e3239',
  gold: '#C8A35F',
};
