export const Config = {
  themes: [
    {
      id: 0,
      color: '#c0c0c0',
    },
    {
      id: 1,
      color: '#000000',
    },
    {
      id: 2,
      color: '#ffffff',
    },
    {
      id: 3,
      color: '#393F47',
    },
    {
      id: 4,
      color: '#ffd700',
    },
    {
      id: 5,
      color: '#0000ff',
    },
    {
      id: 6,
      color: '#008000',
    },
    {
      id: 7,
      color: '#800080',
    },
    {
      id: 8,
      color: '#ffa500',
    },
    {
      id: 9,
      color: '#ffc0cb',
    },
    // {
    //   id: 10,
    //   color: '#63C67E',
    // },
    // {
    //   id: 11,
    //   color: '#FD7859',
    // },
    // {
    //   id: 12,
    //   color: '#BB84F1',
    // },
  ],

  contacts: [
    {
      title: 'Логист',
      phone: '+375-44-553-07-14',
      phoneHref: 'tel:+375445530714',
    },
    {
      title: 'Логист',
      phone: '+375-29-500-51-10',
      phoneHref: 'tel:+375295005110',
    },
    {
      title: 'Бухгалтерия',
      phone: '+375-29-705-56-00',
      phoneHref: 'tel:+375297055600',
    },
    {
      title: 'Бухгалтерия',
      phone: '+375-29-710-87-78',
      phoneHref: 'tel:+375297108778',
    },
    {
      title: 'Электронная почта',
      phone: 'metkomvit@mail.ru',
      phoneHref: 'mailto:metkomvit@mail.ru',
    },
  ],
};
