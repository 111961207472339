import {Button, Drawer, Modal, Tooltip} from 'antd';
import {
  AddressIcon,
  BurgerCloseIcon,
  BurgerIcon,
  InstagramIcon,
} from '../../UI/CastomIcons';
import useWindowSize from '../../Hook/useWindowSize';
import {useEffect, useState} from 'react';
import classNames from 'classnames';
import SVGComponent from '../../UI/logoIcons';
import {theme} from '../../utils/theme';
import LogoIcon from '../../UI/logoIcons';
import {Config} from '../../utils/Config';
import {useNavigate} from 'react-router-dom';

const Header = () => {
  //
  const navigate = useNavigate();
  //
  const links = [
    {
      key: 'services',
      name: 'Расчёт',
      click: () => {
        const services = document.getElementById('calc');
        if (services) {
          services.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
          });
        }
        setOpen(false);
      },
    },
    {
      key: 'about',
      name: 'О компании',
      click: () => {
        const services = document.getElementById('info');
        if (services) {
          services.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
          });
        }
        setOpen(false);
      },
    },
    {
      key: 'vacancies',
      name: 'Вакансии',
      click: () => {
        // navigate('/vacansies');
        window.open('https://rabota.by/employer/3052051', '_blank');
      },
    },
    {
      key: 'contacts',
      name: 'Контакты',
      click: () => {
        setOpenContact(c => !c);
        setOpenModal(false);
        setOpen(false);
      },
    },
  ] as {
    key: string;
    name: string;
    click?: () => void;
  }[];

  const {width} = useWindowSize();
  //
  const [scrollHeight, setScrollHeight] = useState(0);
  const [open, setOpen] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [openContact, setOpenContact] = useState(false);
  //
  const handleScroll = () => {
    setScrollHeight(window.scrollY);
  };
  //
  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <>
      <div className='t-header'>
        {width > 768 && (
          <div className='t-wrapper'>
            <a href='tel:+375445530714' className='t-header__tel link-hover'>
              {'+375-44-553-07-14'}
            </a>
            <span className='t-header__tel '>
              {'Перевозка грузов с точностью в деталях'}
            </span>
            <a href='mailto:metkomvit@mail.ru' className='t-header__email'>
              {'metkomvit@mail.ru'}
            </a>
          </div>
        )}
      </div>
      <div
        className={classNames('t-header__footer', {
          active: scrollHeight > 40,
        })}>
        <div className='t-wrapper'>
          <div className='t-header__footer-logo'>
            <a className='t-header__logo' href='/'>
              <LogoIcon props={{fill: theme.gold}} width={150} height={50} />
            </a>
            {width > 640 && (
              <Button
                type='text'
                onClick={() => {
                  setOpenModal(o => !o);
                }}
                className='t-header__footer-address'>
                {<AddressIcon />}
                <Tooltip
                  color={theme.gold}
                  title='Нажми что бы посмотреть на карте'>
                  <Button
                    href='https://yandex.by/maps/-/CCU1JGhWHB'
                    target='_blank'
                    className='modalLink'
                    type='link'>
                    <div className='address-wrapper'>
                      <span className='address'>
                        {`Адрес: Беларусь, Витебск,`}
                      </span>
                      <span className='address'>
                        {`ул. Терешковой, д. 3А, каб. 26`}
                      </span>
                    </div>
                  </Button>
                </Tooltip>
              </Button>
            )}
          </div>
          {width > 768 ? (
            <div className='t-header__footer-links'>
              {links.map(item => (
                <Button onClick={item.click} type='link' className='link-item'>
                  {item.name}
                </Button>
              ))}
            </div>
          ) : (
            <Button
              className={classNames('t-header__burger-btn', {
                open: open,
              })}
              type='text'
              onClick={() => {
                setOpen(v => !v);
              }}>
              {open ? <BurgerCloseIcon /> : <BurgerIcon />}
            </Button>
          )}
          {width > 768 && (
            <a
              className='t-header__footer-insta'
              href='https://instagram.com/ooometkom?igshid=Y2IzZGU1MTFhOQ=='
              target='_blank'
              rel='noreferrer'>
              <InstagramIcon />
            </a>
          )}
        </div>
        <div
          onClick={() => {
            setOpen(false);
          }}
          className={classNames('t-header__burger-layout', {
            open: open,
          })}
        />
        <div
          onClick={() => {
            setOpen(false);
          }}
          className={classNames('t-header__burger-bg', {
            open: open,
          })}
        />
      </div>
      <Drawer
        open={open}
        onClose={() => {
          setOpen(false);
        }}
        closeIcon={<BurgerCloseIcon />}
        className='t-header__drawer'
        width={'100%'}>
        <div className='t-header__mobile'>
          {links.map(item => (
            <Button onClick={item.click} type='link' className='link-item'>
              {item.name}
            </Button>
          ))}
          <div className='t-header__mobile-contacts'>
            <Button
              style={{marginTop: 30}}
              type='text'
              href='https://yandex.by/maps/-/CCU1JGhWHB'
              target='_blank'
              onClick={() => {
                setOpen(false);
              }}
              className='t-header__footer-address'>
              {<AddressIcon />}
              <span className='title'>{'Наши офисы'}</span>
            </Button>
            <Button
              type='text'
              style={{marginTop: 30}}
              className='t-header__footer-address'
              href='https://instagram.com/ooometkom?igshid=Y2IzZGU1MTFhOQ=='
              target='_blank'
              rel='noreferrer'>
              <InstagramIcon />
              <span className='title'>{'Instagram'}</span>
            </Button>
          </div>
        </div>
      </Drawer>

      <Modal
        className='t-header__modal-wrapper'
        open={openModal}
        onCancel={() => {
          setOpenModal(false);
        }}
        onOk={() => {
          setOpenModal(false);
        }}
        title={
          <>
            {/* <span className='t-header__modal-title'>{'OOO мет ком'}</span> */}
            <SVGComponent props={{fill: theme.gold}} width={200} height={100} />
          </>
        }>
        <div className='t-header__addresses'>
          <Tooltip color={theme.gray} title='Нажми что бы посмотреть на карте'>
            <Button
              href='https://yandex.by/maps/-/CCU1JGhWHB'
              target='_blank'
              className='modalLink'
              type='link'>
              <span className='address'>
                {'Адрес: Беларусь, Витебск, ул. Терешковой, д. 3А, каб. 26'}
              </span>
            </Button>
          </Tooltip>

          {/* <div>
            <iframe
              title='123'
              src='https://yandex.by/map-widget/v1/?ll=30.246330%2C55.178075&mode=search&oid=1119584606&ol=biz&z=17.41'
              className='t-header__modal-iframe'></iframe>
          </div> */}
        </div>
      </Modal>
      <Modal
        className='t-header__modal-wrapper'
        open={openContact}
        onCancel={() => {
          setOpenContact(false);
        }}
        onOk={() => {
          setOpenContact(false);
        }}
        title={
          <div className='t-contact__header'>
            <SVGComponent props={{fill: theme.gold}} width={200} height={100} />
            <span className='title'>{'Контакты'}</span>
          </div>
        }
        // closeIcon={<BurgerCloseIcon />}
      >
        <div className='t-contacts'>
          {Config.contacts.map((item, key) => (
            <div className='t-contacts__item'>
              <span className='title'>{item.title + ':'}</span>
              <a className='link' href={item.phoneHref} rel='noreferrer'>
                {item.phone}
              </a>
            </div>
          ))}
        </div>
      </Modal>
    </>
  );
};

export default Header;
