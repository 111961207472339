import {Layout} from 'antd';
import {theme} from '../../utils/theme';
import Header from '../Header';
import {Content} from 'antd/es/layout/layout';
import AppRouter from '../../router';

const MainLayout = () => {
  return (
    <Layout>
      <Header />
      <Layout style={{background: theme.dark}}>
        <Content>
          <AppRouter />
        </Content>
      </Layout>
    </Layout>
  );
};

export default MainLayout;
