import {createContext} from 'react';
import {action, observable, makeObservable} from 'mobx';
import {User} from '../types';
import api from '../api/api';

class UserStore {
  user: User | null = null;
  // constructor() {
  //   makeObservable(this, {
  //     user: observable,
  //     setUser: action.bound,
  //   });

  //   this.getUser();
  // }

  email: string | null = null;

  // setUser = (newUser: User | null) => {
  //   this.user = newUser;
  // };

  // getUser = async () => {
  //   if (!localStorage.getItem('token')) {
  //     return;
  //   }
  //   const newUser = await api('account?expand=favorites,favorites-groups');
  //   if (newUser.error) {
  //     return;
  //   }
  //   this.user = newUser;
  // };
}

export default createContext(new UserStore());
