import {Result} from 'antd';
import {lazy, Suspense} from 'react';
import {Route, Routes} from 'react-router-dom';

const HomePage = lazy(() => import('../pages/HomePage'));
const VacanciesPages = lazy(() => import('../pages/VacanciesPages'));

const AppRouter = () => {
  return (
    <Suspense fallback={null}>
      <Routes>
        <Route path='/' element={<HomePage />} />
        <Route path='/vacansies' element={<VacanciesPages />} />
        <Route path='*' element={<Result status='404' title='404' />} />;
      </Routes>
    </Suspense>
  );
};

export default AppRouter;
