import React, {useContext} from 'react';
import 'antd/dist/reset.css';
import '../src/less/index.less';
import UserStore from './store/UserStore';
import ru from 'antd/lib/locale/ru_RU';
import {Provider} from 'mobx-react';
import {BrowserRouter} from 'react-router-dom';
import {ConfigProvider} from 'antd';
import MainLayout from './components/MainLayout';
import {theme} from './utils/theme';

function App() {
  //
  const storeUser = useContext(UserStore);

  return (
    <Provider storeCommon={storeUser}>
      <BrowserRouter>
        <ConfigProvider
          theme={{
            token: {
              colorPrimary: theme.gold,
              colorLink: theme.gold,
              linkHoverDecoration: theme.gold,
              colorLinkHover: theme.gold,
            },
          }}
          locale={ru}>
          <MainLayout />
        </ConfigProvider>
      </BrowserRouter>
    </Provider>
  );
}

export default App;
